import { core } from "../../../../config/pluginInit";
import { _ } from "vue-underscore";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
export default {
  name: "AddUser",
  data() {
    return {
      user: {
        id: 0,
        name: "",
        userrole_id: "",
        email: "",
        countryIti: "",
        password: "",
        firstname: "",
        lastname: "",
        not_ask_to_reset: "",
        user_instructions_email: "",
        phoneNumber: 0,
        extension: "",
        rc_ext_id: "",
        rc_jwt: "",
        direct_line: "",
        direct_line_prefix: "",
        contact_number_prefix: "",
      },
      companyList: [],
      loading: false,
      teamList: [],
      rolelist: [],
      departmentList: [],
      backUrl: "/users",
      users: [],
      extensionList: [],
      extensions: {
        phoneNumber: 0,
        extensionNumber: "",
        extensionId: "",
      },
      directIti: null,
      contactIti: null,
    };
  },
  computed: {
    fullName: function () {
      return this.user.firstname + " " + this.user.lastname;
    },
  },
  methods: {
    add() {
      let _vm = this;
      this.user.direct_line_prefix =
        "+" + this.directIti.getSelectedCountryData().dialCode;
      this.user.contact_number_prefix =
        "+" + this.contactIti.getSelectedCountryData().dialCode;

      _vm.user.login_as_agent = _vm.user.login_as_agent == true ? 1 : 0;

      let fd = new FormData();
      _vm.extensionList.push(this.extensions);
      if (_vm.user.profileImage) {
        fd.append("profileImage", _vm.user.profileImage);
      }
      Object.keys(_vm.user).forEach((key) => {
        fd.append(key, _vm.user[key]);
      });
      this.axios.post("/user", fd).then(function (response) {
        _vm.$router.push(_vm.backUrl);
      });
    },
    validateUser() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
      this.user.id > 0 ? this.update() : this.add();
    },

    update() {
      let _vm = this;
      this.user.direct_line_prefix =
        "+" + this.directIti.getSelectedCountryData().dialCode;
      this.user.contact_number_prefix =
        "+" + this.contactIti.getSelectedCountryData().dialCode;
      _vm.user.login_as_agent = _vm.user.login_as_agent == true ? 1 : 0;

      let fd = new FormData();
      if (_vm.user.profileImage) {
        fd.append("profileImage", _vm.user.profileImage);
      }
      Object.keys(_vm.user).forEach((key) => {
        fd.append(key, _vm.user[key]);
      });
      fd.append("_method", "PATCH");
      this.axios.post("/user/" + this.user.id, fd).then(function (response) {
        _vm.user = response.data.data.data;
        _vm.$router.push(_vm.backUrl);
      });
    },
    getQueryString() {
      const queryString = "?search=";
      return queryString;
    },

    selectImage(event) {
      let _vm = this;
      var input = event.target;
      if (input.files && input.files[0]) {
        _vm.user.profileImage = input.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#imagePreview").css(
            "background-image",
            "url(" + e.target.result + ")"
          );
          $("#imagePreview").hide();
          $("#imagePreview").fadeIn(650);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/user/" + this.$route.params.id)
        .then(function (response) {
          _vm.user = response.data.data;
          _vm.user.role = _vm.user.roles[0].id;
          if (_vm.user.full_image_url && _vm.user.full_image_url.length > 0) {
            $("#imagePreview").css(
              "background-image",
              "url(" + _vm.user.full_image_url + "?date=" + Date.now() + ")"
            );
            $("#imagePreview").hide();
            $("#imagePreview").fadeIn(650);
          }
          _vm.directIti.setNumber(
            _vm.user.direct_line_prefix + _vm.user.direct_line
          );
          _vm.contactIti.setNumber(
            _vm.user.contact_number_prefix + _vm.user.contact_number
          );
          this.getExtension();
          _vm.$nextTick(() => {});
          //    const input1 = document.querySelector("#contact-number");
          // _vm.contactIti = intlTelInput(input1, {
          //   separateDialCode: true,
          // });
          // const prefix = _vm.contactIti.getSelectedCountryData().dialCode;
        })
        .catch(function () {});
    },
    getRole() {
      const _vm = this;
      this.axios
        .get("/roles-details" + _vm.rolelist)
        .then(function (response) {
          _vm.rolelist = response.data.data;
        })
        .catch(function () {});
    },
    getTeam() {
      const _vm = this;
      this.axios
        .get("/team-details", _vm.teamList)
        .then(function (response) {
          _vm.teamList = response.data.data;
        })
        .catch(function () {});
    },
    getDepartment() {
      const _vm = this;
      this.axios
        .get("/department-details", _vm.departmentList)
        .then(function (response) {
          _vm.departmentList = response.data.data;
        })
        .catch(function () {});
    },

    getExtension() {
      const _vm = this;
      this.user.direct_line_prefix =
        "+" + this.directIti.getSelectedCountryData().dialCode;
      this.axios
        .get(
          "/get-extension-id/" +
            _vm.user.direct_line_prefix +
            _vm.user.direct_line
        )
        .then(function (response) {
          _vm.user.extension = response.data.data.extensionNumber;
          _vm.user.rc_ext_id = response.data.data.extensionId;
        })
        .catch(function () {});
    },
    delayFetch: _.debounce(function () {
      this.getExtension();
    }, 400),

    getCompany() {
      let _vm = this;
      this.axios
        .get("company")
        .then(function (response) {
          _vm.companyList = response.data.data.data;
        })
        .catch(function () {});
    },
  },
  mounted() {
    const input1 = document.querySelector("#contact-number");
    this.contactIti = intlTelInput(input1, {
      separateDialCode: true,
    });
    const input = document.querySelector("#direct_line");
    this.directIti = intlTelInput(input, {
      separateDialCode: true,
    });
    this.getCompany();
    this.getTeam();
    this.getRole();
    this.getDepartment();
    core.index();
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
  },
};
